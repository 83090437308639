.footer {
    background: #141413;
    position: absolute;
    width: calc(100vw - (100vw - 100%));
}

.footer h1 {
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    margin-right: 70px;
}

.footer a,
.footer li {
    display: flex;
    flex-direction: column;
    color: #FFF;
    font-family: "Roboto Serif";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    list-style-type: none;
}

.socials {
    margin-left: 70px;
    margin-bottom: 20px;
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
    .footer h1 {
        font-size: 36px;
        /* Adjust font size for smaller screens */
        margin-right: 20px;
        /* Adjust margin for smaller screens */
    }

    .footer a,
    .footer li {
        font-size: 24px;
        /* Adjust font size for smaller screens */
    }
}

/* Small devices (portrait phones, 321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .footer h1 {
        font-size: 48px;
        /* Adjust font size for smaller screens */
        margin-right: 20px;
        /* Adjust margin for smaller screens */
    }
}

/* Small devices (portrait tablets and large phones, 481px and up) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .footer h1 {
        font-size: 64px;
        /* Adjust font size for small tablets */
    }

    .footer a,
    .footer li {
        font-size: 28px;
        /* Adjust font size for small tablets */
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .footer h1 {
        font-size: 80px;
        /* Adjust font size for medium tablets */
    }

    .footer a,
    .footer li {
        font-size: 32px;
        /* Adjust font size for medium tablets */
    }
}

/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
    /* Add styles specific for large devices if needed */
}