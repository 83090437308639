/* App.css */

/* Style for the entire app */
* {
  font-family: "Onest", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.App {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

/* Style for the Intro component (full-page overlay) */
.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, visibility 1s;
  z-index: 9999;
  /* Ensure it's above other content */
}

.intro.show {
  opacity: 1;
  visibility: visible;
}

/* Style for the content container (Home page) */
.content-container {
  position: relative;
  /* Ensure it's in the normal document flow */
  z-index: 1;
  /* Place it above the background */
}

/* Add your existing animation styles for .content here */
section {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 40px;
  transition: all 0.3s ease;
}