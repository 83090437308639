* {
    margin: 0;
}

.home-container {
    background-color: #141414;
    height: 100vh;
}

.title {
    color: #FFF;
    font-family: 'Playfair Display SC', serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: left;
    padding: 10% 5%;
}

.introduction {
    color: #FFF;
    font-family: Roboto Serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 40%;
    padding: 5%;
    padding-top: 10%;
}

.bottom-section {
    display: flex;
    flex-direction: row;
}

.connect {
    color: #766E6E;
    font-family: Roboto Serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: auto;
    margin-right: 70px;
    padding-top: 9%;
    display: flex;
    flex-direction: column;
    gap: 2%;
  
}

.connect li {
    list-style-type: none
}

.connect a {
    text-decoration: none;
    color: #766E6E;
}

.hr {
    background-color: #766E6E;
}

.image-container {
    position: relative;
}

.hover-image {
    max-width: 100%;
    transition: all 0.3s ease-in-out;
}

.home-container:hover .hover-image {
    transform: scale(0.5);
    /* Scale down the image on hover */
    margin-top: -40%;
    margin-left: -200%;
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
    .title {
        font-size: 3rem;
        /* Adjust font size for smaller screens */
    }

    .image-container {
        display: none;
    }

    .introduction {
        width: 90%;
        font-size: 1.5rem;

    }

    .bottom-section {
        flex-direction: column;
        padding-top: 50%;
    }

    .connect {
        display: none;
    }



}

/* Small devices (portrait phones, 321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .title {
        font-size: 3rem;
        padding-top: 90px;
    }

    .introduction {
        width: 90%;
        font-size: 1.2rem;
        margin-top: -70px;
    }

    .image-container {
        display: none;
    }

    .bottom-section {
        flex-direction: column;
        padding-top: 50%;
    }

    .connect {
        display: none;
    }

}

/* Small devices (portrait tablets and large phones, 481px and up) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .title {
        font-size: 3rem;
        padding-top: 90px;
    }

    .introduction {
        width: 90%;
        font-size: 1.5rem;

    }

    .image-container {
        display: none;
    }

    .bottom-section {
        flex-direction: column;
        padding-top: 50%;
    }

    .connect {
        display: none;
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .title {
        font-size: 3rem;
        /* Adjust font size for smaller screens */
    }

    .introduction {
        width: 60%;
        /* Adjust width to allow space for .connect */
        font-size: 1.5rem;

        padding-right: 5%;
        /* Add padding to separate from .connect */
    }

    .bottom-section {
        display: flex;
        flex-direction: row;
        /* Ensure flex direction is row */
        justify-content: space-between;
        /* Space elements evenly */
        align-items: flex-start;
        /* Align items at the top */
        padding-top: 45%;
    }

    .connect {
        display: flex;
        /* Ensure .connect is visible */
        flex-direction: column;
        gap: 2%;
        width: 30%;
        /* Adjust width to allow space for .introduction */
        align-items: flex-start;
        /* Align items at the top */
    }
}



/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
    /* Add styles specific for large devices if needed */
}