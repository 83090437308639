.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  padding: 15px;
  width: 100%;
  z-index: 9999;
  background-color: transparent;

  top: 0;

}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 15px;
  margin-left: auto;
  margin-right: 70px;

}

.logo {
  margin-right: auto;
  margin-left: 70px;
  width: 53px;
  height: 50px;


}



.bar {
  border-radius: 4px;
  width: 40px;
  height: 4px;
  background-color: #766E6E;
  margin: 4px 0;
  transition: 0.4s;
  z-index: 9999999;

}

.hamburger-menu:hover .bar:nth-child(1) {
  width: 50px;
  /* Make the first bar 60px wide */
}

.hamburger-menu:hover .bar:nth-child(2) {
  width: 30px;
  /* Make the second bar 20px wide */
}

.open>.bar:nth-child(1) {
  opacity: 0;
}

.open {
  background-color: rebeccapurple;
}

.popout {
  display: none;
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1em;
  box-sizing: border-box;
  background-color: black;

}


.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: transparent;
  border: 3px solid #ffff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-family: "Roboto serif";

}

.navelement {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 100px;
  /* Adjust as needed */
  color: #FFF;
  font-family: "Playfair Display SC";
  font-size: 36px;




}

hr {
  width: 50%;
  /* Adjust the percentage value as needed */

}

.spacer {
  margin: 50px 0;
  /* Adjust as needed */
}

.nav-parents {
  margin: 0 auto;
  /* Centers the HR element */

}

.navbar-container {
  display: flex;
  justify-content: center;
}

.navelement a {
  text-decoration: none;
  color: #FFF;
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
  .logo {
    margin-left: 10px;
  }

  .hamburger-menu {
    margin-right: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }

  .navelement {
    font-size: 28px;
    text-decoration: none;
  }

  .navbar {
    height: fit-content;
  }

}

/* Small devices (portrait phones, 321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .logo {
    margin-left: 10px;
  }

  .hamburger-menu {
    margin-right: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }

  .navelement {
    font-size: 28px;
    text-decoration: none;
  }

  .navbar {
    height: fit-content;
  }
}

/* Small devices (portrait tablets and large phones, 481px and up) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .logo {
    margin-left: 10px;
  }

  .hamburger-menu {
    margin-right: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }

  .navelement {
    font-size: 28px;
    text-decoration: none;
  }

  .navbar {
    height: fit-content;
  }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .logo {
    margin-left: 10px;
  }

  .hamburger-menu {
    margin-right: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }

  .navelement {
    font-size: 28px;
    text-decoration: none;
  }

  .navbar {
    height: fit-content;
  }
}

/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
  /* Add styles specific for large devices if needed */
}