.intro {
    display: flex;
    background: #000;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s;
}

.intro.show {
    opacity: 1;
    visibility: visible;
}

.content {
    position: relative;
}

.content h2 {
    color: #fff;
    font-size: 8em;
    position: absolute;
    transform: translate(-50%, -50%);
}

.content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #ffffff;
}

.content h2:nth-child(2) {
    color: #ffffff;
    animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
    0%,
    100% {
        clip-path: polygon(
            0% 45%,
            16% 44%,
            33% 50%,
            54% 60%,
            70% 61%,
            84% 59%,
            100% 52%,
            100% 100%,
            0% 100%
        );
    }

    50% {
        clip-path: polygon(
            0% 60%,
            15% 65%,
            34% 66%,
            51% 62%,
            67% 50%,
            84% 45%,
            100% 46%,
            100% 100%,
            0% 100%
        );
    }
}
/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
    
    .content h2 {
        
        font-size: 4em;
      
    }
   
   }
   
   /* Small devices (portrait phones, 321px to 480px) */
   @media only screen and (min-width: 321px) and (max-width: 480px) {
     /* Add styles specific for small devices if needed */
   }
   
   /* Small devices (portrait tablets and large phones, 481px and up) */
   @media only screen and (min-width: 481px) and (max-width: 768px) {
   
   }
   
   /* Medium devices (landscape tablets, 769px and up) */
   @media only screen and (min-width: 769px) and (max-width: 1024px) {
     
   }
   
   /* Large devices (laptops/desktops, 1025px and up) */
   @media only screen and (min-width: 1025px) {
     /* Add styles specific for large devices if needed */
   }
   