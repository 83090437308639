.projects {
    background-color: #141413;
    height: auto;
}

.scroll-text {
    color: #FFF;
    font-family: Playfair Display SC;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 5%;
    flex-direction: row;
}

.scroll-container {
    border: 3px solid black;
    border-radius: 5px;
    overflow: hidden;
}

.wrapper {
    max-width: 100%;
    overflow: hidden;

}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: marquee 90s linear infinite;
    margin-top: 24px;
    margin-bottom: 14px;

}

.marquee p {
    display: inline-block;
    color: white;
    font-size: 4rem;
    font-family: Playfair Display SC;

}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0);
    }
}

.scroll-text {
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation: my-animation 60s linear infinite;
    -webkit-animation: my-animation 60s linear infinite;
    animation: my-animation 60s linear infinite;
}

.project-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px 150px;
    transition: transform .2s;
}

.project-container img {
    max-width: 20%;
    padding-right: 2%;
}

.project-container p {
    color: #FFFFFF;
    font-size: 24px;
    padding-left: 15%;
}

.projects hr {
    width: 79.9%;
    margin-left: 20%;
}

.project-container:hover {
    transform: scale(1.1);
}

.link {
    text-decoration: none;
}

.project-text {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    /* Adjust as needed */
    margin-left: -50px;
    /* Adjust as needed */
    font-size: 24px;
    /* Adjust as needed */
    font-weight: bold;
    /* Adjust as needed */
    color: #000;
    /* Adjust as needed */
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}




/* for Firefox */
@-moz-keyframes my-animation {
    0% {
        -moz-transform: translateX(-100%);
    }

    100% {
        -moz-transform: translateX(100%);
    }
}

/* for Chrome */
@-webkit-keyframes my-animation {
    0% {
        -webkit-transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

@keyframes my-animation {
    0% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    100% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
    .scroll-text {
        font-size: 36px;
        width: 300%;
    }

    .project-container {
        margin: 10px;
    }

    .project-container img {
        max-width: 40%;
        padding-right: 0;


    }

    .socials {
        margin-left: 20px !important;
    }

    .projects hr {
        width: 79.9%;
        margin-left: auto;
        margin-right: auto;
    }

    .projects p {
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* Small devices (portrait phones, 321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
    .scroll-text {
        font-size: 36px;
        width: 300%;
    }

    .project-container {
        margin: 10px;
    }

    .project-container img {
        max-width: 40%;
        padding-right: 0;


    }

    .socials {
        margin-left: 20px !important;
    }

    .projects hr {
        width: 79.9%;
        margin-left: auto;
        margin-right: auto;
    }

    .projects p {
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* Small devices (portrait tablets and large phones, 481px and up) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .scroll-text {
        font-size: 48px;
        width: 300%;
    }

    .project-container {
        margin: 10px;
    }

    .project-container img {
        max-width: 40%;
        padding-right: 0;


    }

    .socials {
        margin-left: 20px !important;
    }

    .projects hr {
        width: 79.9%;
        margin-left: auto;
        margin-right: auto;
    }

    .projects p {
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .scroll-text {
        font-size: 62px;
        width: 300%;
    }

    .project-container {
        margin: 10px;
    }

    .project-container img {
        max-width: 40%;
        padding-right: 0;


    }

    .socials {
        margin-left: 20px !important;
    }

    .projects hr {
        width: 79.9%;
        margin-left: auto;
        margin-right: auto;
    }

    .projects p {
        margin-top: auto;
        margin-bottom: auto;
    }
}

/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
    .scroll-text {
        font-size: 3.2rem;

    }

}