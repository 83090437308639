.projects-header-img {
  border-radius: 5%;
  width: 40%;
}

.project {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project h2 {
  text-align: left;
  color: #FFF;
  font-family: "Playfair Display SC";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-start;
  padding-left: 190px;
  margin-top: 90px;
}

.flex-col p {
  width: 300%;
  line-height: 150%;
}

.project-description {
  width: 100% !important;
}

.project h3 {
  color: #FFF;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 20%;
  align-items: flex-start;
  /* Align items to the start (left) of the container */
  width: 80%;

}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -20px;
}



.extra-info {
  margin-bottom: 10px;
  margin-right: 200px;
}



.long-hr {
  margin: 30px -20px 20px;
  border: 0;
  border-top: 1px solid #c9c7c7;
  width: 80%;
}


.process-section {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding-left: 10%;
  padding-top: 3%;
  color: #000000;
  gap: 1%;
  
}

#section {
  position: sticky;
  top: 0;
  background-color: #FFFFFF;
  z-index: 2;
  border-radius: 40px 40px 0px 0px;
  transition: all 0.3s ease;
  width: 100%;
  color: #000000;
  padding-bottom: 5%;
  font-size: 20px;
  line-height: 1.5rem;
}

.section-intro-project {
  background-color: #141413;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  background: #141413;
  color: #FFF;
  height: 100vh;
overflow-y: auto;
  border-radius: 0;
  scrollbar-width: none; 
  -ms-overflow-style: none; 

  
 
}

.process-section h3 {
  color: #000000;
}

.process-section p {
  width: 70%;
  line-height: 1.5rem;
}

.process-section img {
  max-width: 20%;
}

.image-container {
  display: flex;
}



.image-container img {
  margin-right: 10px;
  /* Adjust spacing between images as needed */
}

a {
  /* text-decoration: none; */
  color: white;
}

/* Extra small devices (phones, 320px and down) */
@media only screen and (max-width: 320px) {
  .project h2 {
    font-size: 24px;
    /* Adjust font size for smaller screens */
    padding-left: 20px;
    /* Adjust padding for smaller screens */
  }

  .flex-row {
    flex-direction: column;
    /* Change flex direction for smaller screens */
    gap: 50px;
    /* Adjust gap for smaller screens */
    width: 100%;
    /* Make the width full for smaller screens */
  }

  .flex-col p {
    max-width: 90%;
    padding-left: 5%;
    font-size: 0.85rem;

  }

  .flex-col {
    align-items: normal;
  }

  .projects-header-img {
    width: 70%;
  }

  .project h3 {
    margin-left: 5%;
    font-size: 1.2rem;
  }

  .section-intro-project {
    height: 100vh;
    margin-bottom: 40%;
    overflow-y: auto;
    justify-content: flex-start;
    padding-bottom: 20px;

  }

  .long-hr {
    margin: 10px;


  }

  .project {
    flex-direction: column;
    /* Change to column layout on mobile */

  }

  .process-section p {
    width: 90%;
    font-size: 16px;
  }

  .process-section img {
    width: 100%;

    max-width: 300px;

    margin-bottom: 20px;

    margin-left: auto;

    margin-right: auto;
  }

  .image-container {
    display: flex;
    flex-direction: column;


  }

  .process-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    padding-left: 0%;
    padding-left: 5%;
  }

  .process-section h3 {
    margin-right: auto;
    margin-left: 0;
    padding-top: 5%;
  }

  .extra-info p {
    font-size: 0.8rem;
  }
}

/* Small devices (portrait phones, 321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .project h2 {
    font-size: 24px;
    /* Adjust font size for smaller screens */
    padding-left: 20px;
    /* Adjust padding for smaller screens */
   
  }

  .flex-row {
    flex-direction: column;
    /* Change flex direction for smaller screens */
    gap: 50px;
    /* Adjust gap for smaller screens */
    width: 100%;
    /* Make the width full for smaller screens */
  }

  .flex-col p {
    max-width: 90%;
    padding-left: 5%;
    font-size: 0.85rem;

  }

  .flex-col {
    align-items: normal;
  }

  .projects-header-img {
    width: 60%;
  }

  .project h3 {
    margin-left: 5%;
    font-size: 1.2rem;
  }

  .section-intro-project {
    min-height: 100vh;
    margin-bottom: 40%;
    overflow-y: hidden;
    justify-content: flex-start;
    padding-bottom: 20px;

  }

  .long-hr {
    margin: 10px;


  }

  .project {
    flex-direction: column;
    /* Change to column layout on mobile */

  }

  .process-section p {
    width: 90%;
    font-size: 16px;
  }

  .process-section img {
    width: 100%;
    /* Ensure images take full width */
    max-width: 300px;
    /* Set maximum width for images */
    margin-bottom: 20px;
    /* Add space between images */
    margin-left: auto;
    /* Center the image horizontally */
    margin-right: auto;
  }

  .image-container {
    display: flex;
    flex-direction: column;


  }

  .process-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    padding-left: 0%;
    padding-left: 5%;
  }

  .process-section h3 {
    margin-right: auto;
    margin-left: 0;
    padding-top: 5%;
  }

  .extra-info p {
    font-size: 0.8rem;
  }
}

/* Small devices (portrait tablets and large phones, 481px and up) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .project h2 {
    font-size: 24px;
    /* Adjust font size for smaller screens */
    padding-left: 20px;
    /* Adjust padding for smaller screens */
  }

  .flex-row {
    flex-direction: column;
    /* Change flex direction for smaller screens */
    gap: 50px;
    /* Adjust gap for smaller screens */
    width: 100%;
    /* Make the width full for smaller screens */
  }

  .flex-col p {
    max-width: 90%;
    padding-left: 5%;
    font-size: 0.85rem;

  }

  .flex-col {
    align-items: normal;
  }

  .projects-header-img {
    width: 60%;
  }

  .project h3 {
    margin-left: 5%;
    font-size: 1.2rem;
  }

  .section-intro-project {
    min-height: 100vh;
    margin-bottom: 40%;
    overflow-y: auto;
    justify-content: flex-start;
    padding-bottom: 20px;

  }

  .long-hr {
    margin: 10px;


  }

  .project {
    flex-direction: column;
    /* Change to column layout on mobile */

  }

  .process-section p {
    width: 90%;
    font-size: 16px;
  }

  .process-section img {
    width: 100%;
    /* Ensure images take full width */
    max-width: 300px;
    /* Set maximum width for images */
    margin-bottom: 20px;
    /* Add space between images */
    margin-left: auto;
    /* Center the image horizontally */
    margin-right: auto;
  }

  .image-container {
    display: flex;
    flex-direction: column;


  }

  .process-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    padding-left: 0%;
    padding-left: 5%;
  }

  .process-section h3 {
    margin-right: auto;
    margin-left: 0;
    padding-top: 5%;
  }

  .extra-info p {
    font-size: 0.8rem;
  }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .project h2 {
    padding-left: 100px;
    margin-top: -3%;
  }

  .flex-row {
    margin-top: 10%;
    width: 95%;
  }
}

/* Large devices (laptops/desktops, 1025px and up) */
@media only screen and (min-width: 1025px) {
  .project h2 {
    padding-left: 170px;
  }
}