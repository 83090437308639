.about {
    height: 100%;
    display: grid;
    /* Use grid layout */
    grid-template-columns: 1fr;
    /* Single column layout */
    grid-template-rows: auto auto;
    /* Auto-sized rows */
    justify-content: left;
    align-items: left;
    padding-left: 10%;
    overflow-y: scroll;
    border-radius: 0;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
  }

  #section-about{
    height: 100vh;

  }
  
  .header-about {
    color: #000;
    font-family: Playfair Display SC;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 24px;
  
    margin-top: 24px;
  }
  
  .skill-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    grid-template-rows: repeat(2, 1fr);
    /* Two rows */
    gap: 5%;
  }
  
  .skill-list p {
    font-family: "Roboto Serif";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    /* Remove any margin */
    padding: 0;
    /* Remove any padding */
  }
  
  .soft-skills,
  .skills,
  .tools,
  .coding-languages {
    line-height: 200%;
    font-size: 1.5rem;
  }
  
  /* Extra small devices (phones, 320px and down) */
  @media only screen and (max-width: 320px) {
    .header-about {
      font-size: 48px;
      /* Adjust font size for smaller screens */
    }
  
    .about {
      gap: 3%;
    }
  
    .soft-skills,
    .skills,
    .tools,
    .coding-languages {
      font-size: 1rem;
    }
  
    .skill-list {
      flex-direction: column;
    }
  
    .skill-list p {
      font-size: 20px;
    }
  }
  
  /* Small devices (portrait phones, 321px to 480px) */
  @media only screen and (min-width: 321px) and (max-width: 480px) {
    .header-about {
      font-size: 48px;
      margin-top: 80px;
    }
  
    .about {
      gap: 3%;
    }
  
    .soft-skills,
    .skills,
    .tools,
    .coding-languages {
      font-size: 1rem;
    }
  
    .skill-list {
      flex-direction: column;
      margin-bottom: 50px;
      grid-template-columns: repeat(1, 1fr);
    }
  
    .skill-list p {
      font-size: 20px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 481px and up) */
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .about {
      padding-left: 5%;
      /* Adjust padding for smaller screens */
    }
  
    .header-about {
      font-size: 48px;
      /* Adjust font size for smaller screens */
      margin-top: 15%;
    }
  
    .skill-list {
      display: grid;
      /* Use grid layout for more control */
      grid-template-columns: repeat(2, 1fr);
      /* Two columns with equal width */
      gap: 0;
      margin-bottom: 180px;
    }
  
    .skills,
    .soft-skills,
    .coding-languages,
    .tools {
      width: 100%;
      /* Each takes full width of the column */
    }
  
    .soft-skills,
    .skills {
      grid-row: 1;
      /* Place soft skills and skills in the first row */
    }
  
    .coding-languages,
    .tools {
      grid-row: 2;
      /* Place coding languages and tools in the second row */
      margin-top: 5%;
      /* Add margin to separate coding languages and tools */
    }
  
    .skill-list p {
      font-size: 20px;
      /* Adjust font size for smaller screens */
      margin: 0;
      /* Remove any margin */
      padding: 0;
      /* Remove any padding */
    }
  
    .soft-skills,
    .skills,
    .tools,
    .coding-languages {
      font-size: 1.2rem;
      /* Adjust font size for smaller screens */
    }
  }
  
  /* Medium devices (landscape tablets, 769px and up) */
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .header-about {
      margin-top: 15%;
    }
  
    .skill-list {
      margin-bottom: 10%;
    }
  
    .soft-skills,
    .skills,
    .tools,
    .coding-languages {
      font-size: 1.2rem;
      /* Adjust font size for smaller screens */
    }
  }
  
  /* Large devices (laptops/desktops, 1025px and up) */
  @media only screen and (min-width: 1025px) {
    /* Add styles specific for large devices if needed */
  }